import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/reservation/dashboard",
    component: () => import("@/views/layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/reservation/register",
        name: "reservation-register",
        component: () => import("@/views/reservation/register-index/index.vue"),
        meta: {
          pageTitle: "註冊預約用戶",
          breadcrumbs: ["註冊預約用戶"],
        },
      },
      {
        path: "share",
        name: "share",
        component: () => import("@/views/common/share/index.vue"),
        meta: {
          pageTitle: "分享拿優惠券",
          showPrevious: true,
        },
      },
      {
        path: "coupon",
        name: "coupon",
        component: () => import("@/views/common/coupon/index.vue"),
        meta: {
          pageTitle: "輸入優惠序號",
          showPrevious: true,
        },
      },
      {
        path: "expert",
        name: "expert",
        component: () => import("@/views/common/expert/index.vue"),
        meta: {
          pageTitle: "專人諮詢",
        },
      },
      {
        path: "become-pilot",
        name: "become-pilot",
        component: () => import("@/views/common/become-pilot/index.vue"),
        meta: {
          pageTitle: "成為專業飛手",
        },
      },
    ],
  },
  {
    path: "/pilot/register",
    component: () => import("@/views/layout/MainLayout.vue"),
    meta: {
      middleware: "reservation-auth",
    },
    children: [
      {
        path: "",
        name: "pilot-register",
        component: () => import("@/views/pilot/pilot-register/Index.vue"),
        meta: {
          pageTitle: "加入飛手",
          breadcrumbs: ["加入飛手"],
        },
      },
    ],
  },
  {
    path: "/reservation",
    redirect: "/reservation/dashboard",
    component: () => import("@/views/layout/MainLayout.vue"),
    meta: {
      middleware: "reservation-auth",
    },
    children: [
      {
        path: "reservation-make",
        name: "reservation-make",
        component: () => import("@/views/reservation/ReservationMake.vue"),
        meta: {
          pageTitle: "我要預約",
          showPrevious: true,
        },
      },
      {
        path: "reservation-edit/:id",
        name: "reservation-edit",
        component: () => import("@/views/reservation/ReservationMake.vue"),
        meta: {
          pageTitle: "修改預約",
          showPrevious: true,
        },
      },
      {
        path: "reservation-status/:id",
        name: "reservation-status",
        component: () => import("@/views/reservation/ReservationStatus.vue"),
        meta: {
          pageTitle: "預約進度追蹤",
          showPrevious: true,
        },
      },
      {
        path: "dashboard",
        name: "reservation-dashboard",
        component: () => import("@/views/reservation/Dashboard.vue"),
        meta: {
          pageTitle: "用戶中心",
          breadcrumbs: ["用戶中心"],
          showHeader: false,
        },
      },
      {
        path: "user-info-settings",
        name: "user-info-settings",
        component: () =>
          import("@/views/reservation/user-info-settings/index.vue"),
        meta: {
          pageTitle: "用戶設定",
          breadcrumbs: ["用戶設定"],
          showPrevious: true,
        },
      },
      {
        path: "data",
        name: "reservation-data",
        children: [
          {
            path: "view/:id",
            name: "reservation-data-view",
            component: () =>
              import(
                "@/views/reservation/reservation-data/reservation-data-view/index.vue"
              ),
            meta: {
              pageTitle: "數據查看",
              breadcrumbs: ["數據查看"],
              showPrevious: true,
              showHeader: false,
            },
          },
          {
            path: "",
            name: "reservation-data-list",
            component: () =>
              import(
                "@/views/reservation/reservation-data/reservation-data-list/index.vue"
              ),
            meta: {
              pageTitle: "取得飛行數據",
              breadcrumbs: ["取得飛行數據"],
              showPrevious: true,
            },
          },
        ],
      },
      {
        path: "quotation/:id",
        name: "reservation-quotation",
        component: () =>
          import("@/views/reservation/reservation-quotation-v2/index.vue"),
        meta: {
          pageTitle: "檢視報價",
        },
      },
      {
        path: "",
        name: "my-reservation",
        component: () => import("@/views/reservation/MyReservation.vue"),
        meta: {
          pageTitle: "訂單管理",
          showPrevious: true,
        },
      },
    ],
  },
  {
    path: "/pilot",
    redirect: "/pilot/dashboard",
    component: () => import("@/views/layout/MainLayout.vue"),
    meta: {
      middleware: "pilot-auth",
    },
    children: [
      {
        path: "dashboard",
        name: "pilot-dashboard",
        component: () => import("@/views/pilot/Dashboard.vue"),
        meta: {
          pageTitle: "機隊中心",
          breadcrumbs: ["機隊中心"],
          showHeader: false,
        },
      },
      {
        path: "information",
        name: "pilot-information",
        component: () => import("@/views/pilot/pilot-information/index.vue"),
        meta: {
          pageTitle: "飛手資訊",
          breadcrumbs: ["飛手資訊"],
          showPrevious: true,
          previousUrl: { name: "pilot-dashboard" },
        },
      },
      {
        path: "license",
        name: "pilot-license",
        component: () => import("@/views/pilot/pilot-license/Index.vue"),
        meta: {
          pageTitle: "我的操作證",
          breadcrumbs: ["我的操作證"],
          showPrevious: true,
        },
      },
      {
        path: "drone",
        name: "pilot-drone",
        children: [
          {
            path: "add",
            name: "pilot-drone-add",
            component: () =>
              import("@/views/pilot/pilot-drone/pilot-drone-add/Index.vue"),
            meta: {
              pageTitle: "新增無人機",
              breadcrumbs: ["新增無人機"],
              showPrevious: true,
            },
          },
          {
            path: "insurance-upload/:id",
            name: "pilot-drone-insurance-upload",
            component: () =>
              import(
                "@/views/pilot/pilot-drone/pilot-drone-insurance-upload/index.vue"
              ),
            meta: {
              pageTitle: "無人機保單上傳",
              breadcrumbs: ["無人機保單上傳"],
              showPrevious: true,
            },
          },
          {
            path: "",
            name: "pilot-drone-list",
            component: () =>
              import("@/views/pilot/pilot-drone/pilot-drone-list/index.vue"),
            meta: {
              pageTitle: "我的無人機",
              breadcrumbs: ["我的無人機"],
              showHeader: false,
              showPrevious: true,
            },
          },
        ],
      },
      {
        path: "service-application",
        name: "pilot-service-application",
        component: () =>
          import("@/views/pilot/pilot-service-application/index.vue"),
        meta: {
          pageTitle: "機隊服務申請",
          breadcrumbs: ["機隊服務申請"],
          showPrevious: true,
        },
      },
      {
        path: "reservation",
        name: "reservation",
        children: [
          {
            path: "data-upload/:id",
            name: "pilot-reservation-data-upload",
            component: () =>
              import("@/views/pilot/pilot-reservation-data-upload/index.vue"),
            meta: {
              pageTitle: "飛手數據交付",
              breadcrumbs: ["飛手數據交付"],
              showPrevious: true,
            },
          },
          {
            path: "pilot-status/:id",
            name: "pilot-status",
            component: () =>
              import("@/views/pilot/pilot-reservation-status/index.vue"),
            meta: {
              pageTitle: "任務狀態",
              breadcrumbs: ["任務狀態"],
              showPrevious: true,
            },
          },
          {
            path: "pilot-invite/:id",
            name: "pilot-invite",
            component: () =>
              import("@/views/pilot/pilot-reservation-invite/index.vue"),
            meta: {
              pageTitle: "派遣邀請",
              breadcrumbs: ["派遣邀請"],
            },
          },
          {
            path: "observer-invite/:id",
            name: "observer-invite",
            component: () =>
              import("@/views/pilot/pilot-reservation-invite/index.vue"),
            meta: {
              pageTitle: "派遣邀請",
              breadcrumbs: ["派遣邀請"],
            },
          },
          {
            path: "",
            name: "pilot-reservation-list",
            component: () =>
              import("@/views/pilot/pilot-reservation-list/index.vue"),
            meta: {
              pageTitle: "飛行任務資訊",
              breadcrumbs: ["飛行任務資訊"],
              showPrevious: true,
            },
          },
        ],
      },
      {
        path: "info-settings",
        name: "pilot-info-settings",
        component: () => import("@/views/pilot/pilot-info-settings/index.vue"),
        meta: {
          pageTitle: "飛行員設定",
          breadcrumbs: ["飛行員設定"],
          showPrevious: true,
        },
      },
    ],
  },
  {
    path: "/",
    children: [
      {
        path: "/login",
        name: "login",
        children: [
          {
            path: "line",
            name: "login-line",
            children: [
              {
                path: "callback",
                name: "login-line-callback",
                component: () => import("@/views/auth/Line/Callback.vue"),
                meta: {
                  pageTitle: "登入",
                },
              },
            ],
          },
          {
            path: "",
            name: "login-index",
            component: () => import("@/views/auth/Login.vue"),
            meta: {
              pageTitle: "登入",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  await store
    .dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() })
    .then(() => {
      // before page access check if page requires authentication
      // if (
      //   to.meta.middleware == "auth" ||
      //   to.meta.middleware == "reservation-auth" ||
      //   to.meta.middleware == "pilot-auth"
      // ) {
      //   if (store.getters.isUserAuthenticated) {
      //     if (to.meta.middleware == "auth") {
      //       next();
      //     }
      //   } else {
      //     next({ name: "login-index" });
      //   }
      //   // eslint-disable-next-line no-dupe-else-if
      // }
      if (to.name == "pilot-reservation-data-upload") {
        next();
      }

      if (to.meta.middleware == "reservation-auth") {
        if (store.getters.currentUser.is_verified) {
          next();
        } else {
          next({ name: "reservation-register", query: { to: to.path } });
        }
      }
      if (to.meta.middleware == "pilot-auth") {
        if (store.getters.currentUser.is_pilot) {
          next();
        } else {
          next({ name: "pilot-register" });
        }
      }
      next();

      // Scroll page to top on every route change
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    })
    .catch(() => {
      next({ name: "login-index" }); // Handle errors, redirect to login if necessary
    });
});
export default router;
